<template>
    <div class="page">
        <el-form size="small" :inline="true" ref="searchForm" class="query-form" :model="searchForm"
                 @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-form-item prop="sysName">
                <el-input size="small" v-model="searchForm.sysName" placeholder="姓名" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="refreshList(1)" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white top">
            <el-row>
                <el-button v-if="hasPermission('verification:add')" type="primary" size="small" icon="el-icon-plus"
                           @click="add()">添加
                </el-button>
                <el-button v-if="hasPermission('verification:delete')" type="danger" size="small" icon="el-icon-delete"
                           @click="del()"
                           :disabled="dataListSelections.length <= 0" plain>删除
                </el-button>
            </el-row>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100% - 80px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        type="selection"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="sysName"
                        label="姓名">
                </el-table-column>
                <el-table-column
                        show-overflow-tooltip
                        prop="sysPhone"
                        label="手机号">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="270"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('verification:delete')" icon="el-icon-delete" type="text"
                                   size="mini" @click="del([scope.row.id])">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background=""
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <el-dialog
                title="添加小程序核销人员"
                width="70%"
                :close-on-click-modal="false"
                :append-to-body="true"
                :visible.sync="visible" height="calc(100vh - 280px)">
            <el-form size="small" :inline="true" class="query-form" :model="userForm">
                <el-form-item prop="name">
                    <el-input size="small" v-model="userForm.name" placeholder="请输入姓名（限20字）" clearable maxlength="20"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getUserList(1)" size="small" icon="el-icon-search">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table
                    :data="userList"
                    v-loading="userLoading"
                    size="small"
                    height="calc(100vh - 480px)"
                    class="table" ref="userTable" @selection-change="selectionChangeHandleUser">
                <el-table-column
                        type="selection"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="name"
                        sortable
                        show-overflow-tooltip
                        label="姓名"></el-table-column>
                <el-table-column
                        prop="mobilePhone"
                        label="手机号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="200"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('verification:add')" type="text" size="small" @click="doSubmit([scope.row])">添加</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center m_t1">
                <el-pagination
                        @size-change="sizeChangeHandleUser"
                        @current-change="currentChangeHandleUser"
                        :current-page="userForm.current"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="userForm.size"
                        :total="userTotal"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">关闭</el-button>
                <el-button size="small" type="primary" :disabled="userListAllSelections.length <= 0" @click="doSubmit()" v-noMoreClick>确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchForm: {
                    sysName: ''
                },
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListSelections: [],
                loading: false,
                // 添加小程序核销人员
                visible: false,
                userForm: {
                    current: 1,
                    size: 10,
                    name: '',
                },
                userTotal: 0,
                userList: [],
                userListSelections: [],
                userListAllSelections: [],
                idKey: 'id',
                userLoading: false,
            }
        },
        activated() {
            this.refreshList(1)
        },
        methods: {
            // 获取数据列表
            refreshList(type) {
                if(type == 1) {
                    this.pageNo = 1
                }
                this.loading = true
                this.$axios(this.api.user.basesysrole, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    ...this.searchForm
                }, 'get').then(data => {
                    if (data.status) {
                        this.dataList = data.data.records
                        if (this.dataList.length == 0 && this.pageNo > 1) {
                            this.pageNo--
                            this.refreshList()
                        }
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // 重置
            resetSearch() {
                this.searchForm.sysName = ''
                this.refreshList(1)
            },
            // 新增
            add() {
                this.visible = true
                this.userForm.current = 1
                this.userForm.name = ''
                this.userList = []
                this.userListSelections = []
                this.userListAllSelections = []
                this.getUserList()
            },
            // 获取用户
            getUserList(type) {
                this.userLoading = true
                if(type == 1) {
                    this.userForm.current = 1
                }
                this.$axios(this.api.user.queryRoleUserList, this.userForm, 'get').then(data => {
                    if (data.status) {
                        this.userList = data.data.records
                        this.userTotal = parseInt(data.data.total)
                        this.userLoading = false
                        this.$nextTick(() => {
                            this.setSelectRow()
                        })
                    }
                })
            },
            // 确定
            doSubmit(row) {
                if(!this.userListAllSelections.length) {
                    this.$message.warning('请选择添加人员')
                    return
                }
                let list = row || this.userListAllSelections
                let p = []
                list.map(item => {
                    p.push({
                        "id": item.id,
                        "sysName": item.name,
                        "sysPhone": item.mobilePhone
                    })
                })
                this.$axios(this.api.user.basesysroleSave, p, 'post').then(data => {
                    this.userLoading = false
                    if (data && data.status) {
                        this.visible = false
                        this.$message.success(data.msg)
                        this.refreshList()
                    }else{
                        this.$message.error(data.msg)
                    }
                })
            },
            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.user.basesysroleBatchRemove, ids, 'post').then(data => {
                        this.loading = false
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.refreshList()
                        }
                    })
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
            // 每页数
            sizeChangeHandleUser(val) {
                this.userForm.size = val
                this.userForm.current = 1
                this.getUserList()
                this.$nextTick(() => {
                    this.changePageCoreRecordData()
                })
            },
            // 当前页
            currentChangeHandleUser(val) {
                this.userForm.current = val
                this.getUserList()
                this.$nextTick(() => {
                    this.changePageCoreRecordData()
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            selectionChangeHandleUser(val) {
                this.userListSelections = val
                this.$nextTick(() => {
                    this.changePageCoreRecordData()
                })
            },
            // 设置选中的方法
            setSelectRow() {
                if (!this.userListAllSelections || this.userListAllSelections.length <= 0) {
                    this.$refs.userTable.clearSelection()
                    return
                }
                // 标识当前行的唯一键的名称
                let idKey = this.idKey
                let selectAllIds = []
                this.userListAllSelections.forEach(row => {
                    selectAllIds.push(row[idKey])
                })
                this.$refs.userTable.clearSelection()
                for (var i = 0; i < this.userList.length; i++) {
                    if (selectAllIds.indexOf(this.userList[i][idKey]) >= 0) {
                        // 设置选中，记住table组件需要使用ref="table"
                        this.$refs.userTable.toggleRowSelection(this.userList[i], true)
                    }
                }
            },
            // 记忆选择核心方法
            changePageCoreRecordData() {
                // 标识当前行的唯一键的名称
                let idKey = this.idKey
                let that = this
                // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
                if (this.userListAllSelections.length <= 0) {
                    this.userListSelections.forEach(row => {
                        that.userListAllSelections.push(row)
                    })
                    return
                }
                // 总选择里面的key集合
                let selectAllIds = []
                this.userListAllSelections.forEach(row => {
                    selectAllIds.push(row[idKey])
                })
                let selectIds = []
                // 获取当前页选中的id
                this.userListSelections.forEach(row => {
                    selectIds.push(row[idKey])
                    // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
                    if (selectAllIds.indexOf(row[idKey]) < 0) {
                        that.userListAllSelections.push(row)
                    }
                })
                let noSelectIds = []
                // 得到当前页没有选中的id
                this.userList.forEach(row => {
                    if (selectIds.indexOf(row[idKey]) < 0) {
                        noSelectIds.push(row[idKey])
                    }
                })
                noSelectIds.forEach(id => {
                    if (selectAllIds.indexOf(id) >= 0) {
                        for (let i = 0; i < that.userListAllSelections.length; i++) {
                            if (that.userListAllSelections[i][idKey] === id) {
                                // 如果总选择中有未被选中的，那么就删除这条
                                that.userListAllSelections.splice(i, 1)
                                break
                            }
                        }
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
